import React from 'react';

import { Dialog } from '@progress/kendo-react-dialogs';
import Button from 'shared/components/button/Button';
import { Internationalization } from '@syncfusion/ej2-base';
import moment from 'moment';
import { formatDateWithMoment } from 'utils';
import { Input } from '@progress/kendo-react-inputs';
import CustomTimeInput from 'shared/components/custom-time-input/CustomTimeInput';
import Switch from 'shared/components/switch/Switch';
import style from '../architecture.module.css';
import Select from 'shared/components/select/Select';
import { ActionTypes } from '../ChannelArchitecture.d';

const ModifyBlock = ({
  schedules,
  setIsDialogOpen,
  blockData,
  numberOfWeeks,
  setNumberOfWeeks,
  titleGroupList,
  seriesList,
  updateItem,
}) => {
  const instance = new Internationalization();

  // Modify Block State
  const [genre, setGenre] = React.useState('');
  const [type, setType] = React.useState('TitleGroup');
  const [programMaxCount, setProgramMaxCount] = React.useState(1);
  const [
    programAvailableDuration,
    setProgramAvailableDuration,
  ] = React.useState(null);
  const [series, setSeries] = React.useState(null);
  const [seasons, setSeasons] = React.useState(null);
  const [titleGroup, setTitleGroup] = React.useState(null);
  const [weekNumber, setWeekNumber] = React.useState(1);
  const [dayOfWeek, setDayOfWeek] = React.useState({ id: 1, name: 'Monday' });
  const [startTime, setStartTime] = React.useState(new Date());
  const [endTime, setEndTime] = React.useState(new Date());
  const [hasLink, setHasLink] = React.useState(false);
  const [layout, setLayout] = React.useState('');
  const [sequential, setSequential] = React.useState(false);

  // Input Component for Modify Block Dialog
  const InputComponent = ({ label, input, gap }) => (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        gap: gap ?? 20,
      }}
    >
      {label && <b>{label}</b>}
      {input}
    </div>
  );

  const daysOfWeek = [
    { id: 1, name: 'Monday' },
    { id: 2, name: 'Tuesday' },
    { id: 3, name: 'Wednesday' },
    { id: 4, name: 'Thursday' },
    { id: 5, name: 'Friday' },
    { id: 6, name: 'Saturday' },
    { id: 7, name: 'Sunday' },
  ];

  // Get the selected day of the week
  const getSelectedDayOfWeek = (date) => {
    // Get the full name of the day of the week
    const day = instance.formatDate(new Date(date), {
      skeleton: 'E',
      type: 'date',
    });
    return daysOfWeek.find((dayOfWeek) => dayOfWeek.name.includes(day));
  };

  // Set the block data to be modified for the Modify Block Dialog
  React.useEffect(() => {
    if (blockData) {
      const {
        State,
        StartTime,
        EndTime,
        Subject,
        IsSequential,
        IsLinked,
        BlockReference,
        TitleGroupName,
        BlockName,
        Genre,
        Week,
        DayOfWeek,
        MaxCount,
        AvailableDuration,
        Layout,
        SeriesName,
        Type,
        SeasonID,
        TitleGroupID,
      } = blockData;
      setType(Type);
      setWeekNumber(Week);
      setDayOfWeek(getSelectedDayOfWeek(StartTime));
      setStartTime(StartTime);
      setEndTime(EndTime);
      setHasLink(IsLinked);
      setSequential(IsSequential);
      setSeries(seriesList.find((o) => o.name === SeriesName));
      var selectedSeries = seriesList.find((o) => o.name === SeriesName);

      setSeasons(
        selectedSeries && SeasonID
          ? selectedSeries.seasons.find((o) => o.id === SeasonID)
          : null
      );
      setGenre(Genre);
      setLayout(Layout);
      setProgramMaxCount(MaxCount);
      setProgramAvailableDuration(AvailableDuration);
      setTitleGroup(titleGroupList.find((o) => o.id === TitleGroupID));
    }
  }, []);

  // Get max number of weeks to populate the week number select
  const getWeeks = () => {
    let weeks = [];
    for (let i = 1; i <= 52; i++) {
      weeks.push(i);
    }
    return weeks.map((week) => ({ id: week, name: week }));
  };

  const handleModifyBlock = (props) => {
    // Change when backend is ready
    if (schedules) {
      const edited = schedules.programmes.find(
        (o) =>
          o.id === props.Id &&
          o.startTime === formatDateWithMoment(blockData.StartTime, 'HH:mm:ss')
      );
      const temp = {
        ...edited,
        genre: genre,
        maxCount: programMaxCount,
        availableDuration: programAvailableDuration,
        week: props.Week,
        dayOfWeek: props.DayOfWeek.id,
        startTime: formatDateWithMoment(props.StartTime, 'HH:mm:ss'),
        endTime: formatDateWithMoment(props.EndTime, 'HH:mm:ss'),
        layout: layout,
        link: hasLink,
        sequential: sequential,
        type: props.type,
        series: series,
        seasonID: seasons && seasons.id,
        seriesID: series && series.id,
        seriesName: series && series.name,
        titleGroupName: titleGroup && titleGroup.name,
        titleGroupID: titleGroup && titleGroup.id,
      };
      updateItem({ item: temp });
    }
    setIsDialogOpen(false);
  };

  const changeType = (args) => {
    setType(args.name);
    setSeries(null);
    setSeasons(null);
    setTitleGroup(null);
  };

  return (
    <Dialog closeIcon={null}>
      <div style={{ padding: '20px' }}>
        {' '}
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            gap: 7,
            fontSize: '20px',
          }}
        >
          Modify Block
          <span>|</span>
          <div style={{ color: '#6D6D73' }}>{blockData.BlockName}</div>
        </div>
        <div style={{ display: 'flex', padding: '25px 0', gap: 30 }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 10,
              flex: 1,
            }}
          >
            <InputComponent
              label="Type"
              input={
                <Select
                  text={type}
                  value={type}
                  list={[
                    { id: 1, name: 'TitleGroup' },
                    { id: 2, name: 'Series' },
                    { id: 3, name: 'Movies' },
                  ]}
                  onSelect={(args) => {
                    changeType(args);
                  }}
                  width="250px"
                />
              }
            />

            <InputComponent
              label="Genre"
              input={
                <Input
                  type="text"
                  value={genre}
                  placeholder="Enter a preferred genre"
                  onChange={(e) => setGenre(e)}
                  style={{ width: '252px' }}
                />
              }
            />

            <InputComponent
              label="Programme MAX Count"
              input={
                <input
                  type="number"
                  value={programMaxCount}
                  min={1}
                  onChange={(e) => setProgramMaxCount(e.target.value)}
                  style={{ width: '155px' }}
                  className={style.inputWrapper}
                />
              }
            />

            <InputComponent
              label="Programme Available Duration"
              input={
                <input
                  type="number"
                  value={programAvailableDuration}
                  min={1}
                  onChange={(e) => setProgramAvailableDuration(e.target.value)}
                  style={{ width: '155px' }}
                  className={style.inputWrapper}
                />
              }
            />

            {type === 'Series' && (
              <>
                <InputComponent
                  label="Series"
                  input={
                    <Select
                      text={
                        series
                          ? series.name
                          : 'Please select a preferred series'
                      }
                      value={series}
                      list={seriesList}
                      onSelect={(args) => {
                        setSeries(args);
                      }}
                      width="250px"
                      disabled={type !== 'Series'}
                    />
                  }
                />

                <InputComponent
                  label="Seasons"
                  input={
                    <Select
                      text={
                        !series
                          ? 'Please select a preferred series'
                          : seasons
                          ? seasons.name
                          : 'Please select a preferred season'
                      }
                      list={series ? series.seasons : []}
                      onSelect={(args) => {
                        setSeasons(args);
                      }}
                      width="250px"
                      disabled={!series}
                    />
                  }
                />
              </>
            )}
            {type === 'TitleGroup' && (
              <>
                <InputComponent
                  label="Title Group"
                  input={
                    <Select
                      text={
                        titleGroup
                          ? titleGroup.name
                          : 'Please select a preferred title group'
                      }
                      value={titleGroup}
                      list={titleGroupList}
                      onSelect={(args) => {
                        setTitleGroup(args);
                      }}
                      width="250px"
                      disabled={type !== 'TitleGroup'}
                    />
                  }
                />{' '}
              </>
            )}
          </div>
          <div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 10,
                flex: 1,
              }}
            >
              <InputComponent
                label="Week Number"
                input={
                  <Select
                    text={weekNumber}
                    list={getWeeks()}
                    onSelect={(args) => {
                      setWeekNumber(args.name);
                    }}
                    width="250px"
                  />
                }
              />

              <InputComponent
                label="Day of Week"
                input={
                  <Select
                    text={dayOfWeek.name}
                    value={dayOfWeek.name}
                    list={daysOfWeek}
                    onSelect={(args) => {
                      setDayOfWeek(args);
                    }}
                    width="250px"
                  />
                }
              />

              <InputComponent
                label="Start Time"
                input={
                  <div
                    style={{ display: 'flex', alignItems: 'center', gap: 15 }}
                  >
                    <CustomTimeInput
                      customWrapper={style.startTimeInputWrapper}
                      value={startTime}
                      onChange={(e) => setStartTime(e)}
                    />
                    <Switch
                      label="Link"
                      labelStyle={{ fontWeight: 'bold', color: '#6D6D73' }}
                      text={['No', 'Yes']}
                      active={!hasLink}
                      disabled={false}
                      onSwitch={(e) => {
                        setHasLink(!e);
                      }}
                      blurredOnInactive={false}
                      cssClass={style.switchClass}
                    />
                  </div>
                }
              />

              <InputComponent
                label={'Link Reference *'}
                input={
                  <Select
                    text={'Please select an existing block'}
                    list={[]}
                    // onSelect={(args) => { setLinkReference(args) }}
                    width="250px"
                    disabled
                  />
                }
              />

              <InputComponent
                label={'End Time'}
                input={
                  <div style={{ width: '250px' }}>
                    <CustomTimeInput
                      customWrapper={style.endTimeInputWrapper}
                      value={endTime}
                      onChange={(e) => setEndTime(e)}
                    />
                  </div>
                }
              />

              <InputComponent
                label={'Layout'}
                input={
                  <Select
                    text={'Please select a layout'}
                    list={[]}
                    onSelect={(args) => {
                      setLayout(args);
                    }}
                    width="250px"
                    disabled
                  />
                }
              />

              <InputComponent
                input={
                  <Switch
                    label="Sequential"
                    labelStyle={{ fontWeight: 'bold', color: '#6D6D73' }}
                    text={['No', 'Yes']}
                    active={!sequential}
                    disabled={false}
                    onSwitch={(e) => {
                      setSequential(!e);
                    }}
                    blurredOnInactive={false}
                    cssClass={style.switchClass}
                  />
                }
              />
            </div>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          <Button
            defaultBtn
            text="Cancel"
            onClick={() => setIsDialogOpen(false)}
          />
          <Button
            text="Update"
            type="button"
            onClick={() => {
              handleModifyBlock({
                ...blockData,
                EndTime: endTime,
                StartTime: startTime,
                DayOfWeek: dayOfWeek,
                Week: weekNumber,
                Genre: genre,
                Layout: layout,
                IsLink: hasLink,
                IsSequential: sequential,
                type: type,
              });
            }}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default ModifyBlock;
