import { ActionTypes } from '../ChannelArchitecture.d';

export const schedulesReducer = (state, { actionType, payload }) => {
  switch (actionType) {
    case ActionTypes.CREATE:
      return {
        ...state,
        programmes: [...state.programmes, payload.item],
      };

    case ActionTypes.UPDATE:
      return {
        ...state,
        programmes: state.programmes.map((item) => {
          if (item.id === payload.item.id) {
            return { ...payload.item };
          }
          return item;
        }),
      };

    case ActionTypes.DELETE:
      return state.filter((item) => item.id !== payload.id);

    case ActionTypes.UPDATEALL:
      return {
        programmes: payload.programmes,
      };

    default:
      return state;
  }
};
