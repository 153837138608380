import * as React from 'react';
import { Internationalization } from '@syncfusion/ej2-base';
import moment from 'moment';
import {
  GetChannelArchitectureAPI,
  ImportChannelArchitectureAPI,
  ExportChannelArchitectureAPI,
  GetSeriesAPI,
  GetTitleGroupLookupAPI,
} from 'api';
import LinkIcon from 'assets/icons/channel-architecture-linked-slot.svg';
import SequentialIcon from 'assets/icons/channel-architecture-linked-slot-sequence.svg';
import BootstrapSpinner from 'shared/components/bootstrap-spinner/BootstrapSpinner';
import useStore from 'store/AccountStore';
import Select from 'shared/components/select/Select';
import { Scheduler } from 'op2mise-react-widgets';
import { getWeekNumber, formatDateWithMoment, addDaysToDate } from 'utils';
import { ImportComponent } from './modals/ImportComponent';
import { Dialog } from '@progress/kendo-react-dialogs';
import Button from 'shared/components/button/Button';
import { Input } from '@progress/kendo-react-inputs';
import CustomTimeInput from 'shared/components/custom-time-input/CustomTimeInput';
import Switch from 'shared/components/switch/Switch';
import ModifyBlock from './modals/ModifyBlock';
import { Genre } from 'constant/AttributeNameConstant';
import { Week } from '@syncfusion/ej2-react-schedule';
import style from './architecture.module.css';
import { padStart } from '@fullcalendar/react';
import { number } from 'prop-types';
import { schedulesReducer } from './reducers/schedules.reducer';
import { ActionTypes } from './ChannelArchitecture.d';

function CAProgrammmes({
  schedulerProps,
  startDate,
  endDate,
  channelInfo,
  calculateDates,
  numberOfWeeks,
  setNumberOfWeeks,
}) {
  const { user } = useStore((state) => state);
  const instance = new Internationalization();
  const blockData = React.useRef({});
  const [openForm, setOpenForm] = React.useState(true);
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  // const [blockData, setBlockData] = React.useState({});
  const [isDirty, setIsDirty] = React.useState(false);
  const { channelId, channelName } = channelInfo;
  const counter = React.useRef(0);
  const [seriesList, setSeriesList] = React.useState([]);
  const [titleGroupList, setTitleGroupList] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const [schedules, dispatch] = React.useReducer(schedulesReducer, {});

  const updateAllItem = (payload) => {
    dispatch({
      actionType: ActionTypes.UPDATEALL,
      payload,
    });
  };

  const updateItem = (payload) => {
    dispatch({
      actionType: ActionTypes.UPDATE,
      payload,
    });
  };

  const deleteItem = (payload) => {
    dispatch({
      actionType: ActionTypes.DELETE,
      payload,
    });
  };

  const createItem = (payload) => {
    dispatch({
      actionType: ActionTypes.CREATE,
      payload,
    });
  };
  // Getting date range of today and tomorrow
  // NOTE: Use addDaysToDate(x, y) from date.utils
  const tomorrow = moment()
    .add(1, 'days')
    .toDate();

  const period = `${formatDateWithMoment(
    new Date().toDateString()
  )} - ${formatDateWithMoment(tomorrow)}`;

  const getEndDate = () => {
    return numberOfWeeks > 1
      ? addDaysToDate(startDate, numberOfWeeks * 7)
      : endDate;
  };

  const dateHeaderTemplate = React.useCallback(
    (args) => {
      const instance = new Internationalization();
      const day = instance.formatDate(args.date, { skeleton: 'E' });
      const weekNumber = getWeekNumber(args.date, startDate, getEndDate());

      return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div
            className="e-header-day"
            style={{ flex: 1, display: 'flex', justifyContent: 'center' }}
          >{`WK${weekNumber}, ${day}`}</div>
        </div>
      );
    },
    [startDate, endDate, numberOfWeeks]
  );

  const eventFields = (args) => ({
    Id: args.id,
    Subject: args.seriesName || args.type,
    StartTime: calculateDates(
      args.dayOfWeek,
      args.week,
      args.startTime,
      args.endTime,
      { startDate, endDate }
    )['startTime'], // Field required: Always convert to new Date(...)
    EndTime: calculateDates(
      args.dayOfWeek,
      args.week,
      args.startTime,
      args.endTime,
      { startDate, endDate }
    )['endTime'], // Field required: Always convert to new Date(...)
    IsLinked: args.link,
    IsSequential: args.sequential,
    BlockReference: args.blockReference,
    TitleGroupName: args.titleGroupName,

    // Below are additional data for modifying the block
    SeriesName: args.seriesName,
    BlockName: args.blockName,
    Genre: args.genre,
    Week: args.week,
    DayOfWeek: args.dayOfWeek,
    MaxCount: args.maxCount,
    AvailableDuration: args.availableDuration,
    Layout: args.layout,
    Type: args.type,
    SeasonID: args.seasonID,
    TitleGroupID: args.titleGroupID,
  });

  const eventTemplate = (args) => {
    const {
      State,
      StartTime,
      EndTime,
      Subject,
      IsSequential,
      IsLinked,
      BlockReference,
      TitleGroupName,
      BlockName,
      Genre,
      Week,
      DayOfWeek,
      MaxCount,
      AvailableDuration,
      Layout,
      SeriesName,
      Id,
      Type,
      SeasonID,
      TitleGroupID,
    } = args;
    /** Converts the date value and extracts the time */
    let instance = new Internationalization();
    const getTimeString = (value) => {
      return instance.formatDate(value, {
        skeleton: 'hm',
        format: State.timeFormat,
      });
    };

    return (
      <div
        className="e-schedule-custom-event"
        onContextMenu={() => {
          blockData.current = {
            State,
            EndTime,
            StartTime,
            Subject,
            IsSequential,
            IsLinked,
            BlockReference,
            TitleGroupName,
            BlockName,
            SeriesName,
            Genre,
            Week,
            DayOfWeek,
            MaxCount,
            AvailableDuration,
            Layout,
            Id,
            Type,
            SeasonID,
            TitleGroupID,
          };
        }}
        style={{
          position: 'absolute',
          height: '98%',
          width: '98%',
        }}
      >
        <div className="e-schedule-custom-event-title">
          {Subject === 'TitleGroup' ? TitleGroupName : Subject}
        </div>
        <div className="e-schedule-custom-event-sub-title">
          <div className="e-schedule-custom-event-sub-title-schedule-duration">{`${getTimeString(
            StartTime
          )} - ${getTimeString(EndTime)}`}</div>
        </div>
        {(IsLinked || IsSequential) && (
          <div
            style={{
              position: 'absolute',
              bottom: 0,
              marginBottom: '4px',
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            {/* {IsSequential && ( <img className='icon-link-sequential' src={SequentialIcon} alt='sequential-icon' width={16} height={16}/> )} */}
            {IsLinked && (
              <div
                style={{ display: 'flex', flexDirection: 'row', width: '100%' }}
              >
                <img
                  className="icon-link-sequential"
                  src={LinkIcon}
                  alt="link-icon"
                  width={16}
                  height={16}
                />
                <span
                  style={{
                    color: 'rgba(250, 250, 250, 0.5)',
                    marginLeft: '1px',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    width: '80%',
                  }}
                >{`${BlockReference} (${Subject})`}</span>
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  const getMaxWeek = (data) => {
    let maxWeek = 0;
    data.map((programme) => {
      if (programme.week > maxWeek) {
        maxWeek = programme.week;
      }
    });
    setNumberOfWeeks(maxWeek);
  };

  React.useEffect(() => {
    // Close the dialog when the component is unmounted
    setIsDialogOpen(false);
    blockData.current = {};
  }, []);

  const programmeSchedulerProps = React.useMemo(
    () => ({
      ...schedulerProps,
      dataSource: schedules,
      eventLookupList: 'programmes',
      loading: isLoading,
      schedule: { start: startDate, end: getEndDate() },
      suppressQuickInfo: true,
      eventTemplate,
      eventFields,
      dateHeaderTemplate,
      suppressTooltip: true,
      suppressContextMenu: false,
      onImport: () => {
        // Put your import logic here
        setOpenForm('IMPORT');
      },
      onExport: () => {
        ExportChannelArchitectureAPI({
          queryParams: { channelId },
          onSuccess: (res) => {
            const { organisationName } = user;
            let exportDate = new Date();
            const exportedDateString =
              padStart(exportDate.getDate(), 2).toString() +
              padStart(exportDate.getMonth() + 1, 2).toString() +
              exportDate.getFullYear().toString();
            const fileName = `${organisationName}_op2mise_channel_architecture_programme_export_${channelName}_${exportedDateString}.xlsx`;
            const blob = new Blob([res], {
              type:
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });
            saveAs(blob, fileName);
          },
        });
      },
      onSlotModified: () => {
        setIsDialogOpen(true);
      },
      onSlotDeleted: (args) => {},
      onSlotCut: (args) => {},
      onDragStop: (args) => {},
      settings: ['SLOT DURATION', 'SLOT INTERVAL'],
      timeoutDelay: 800,
    }),
    [schedules, channelInfo, startDate, endDate, numberOfWeeks]
  );

  React.useEffect(() => {
    if (channelId || isDirty) {
      GetChannelArchitectureAPI({
        queryParams: { channelId },
        onSuccess: (response) => {
          updateAllItem({ programmes: response });
          getMaxWeek(response);
          setIsDirty(false);
        },
        setLoader: setIsLoading,
      });
      GetSeriesAPI({
        queryParams: { channelId },
        onSuccess: (response) => {
          setSeriesList(response.series);
        },
        setLoader: setIsLoading,
      });
      GetTitleGroupLookupAPI({
        onSuccess: (response) => {
          setTitleGroupList(response);
        },
        setLoader: setIsLoading,
      });
    }
  }, [channelId, isDirty]);

  const RenderScheduler = React.useCallback(() => {
    return (channelId === 0) & !channelName ? null : (
      <Scheduler {...programmeSchedulerProps} />
    );
  }, [programmeSchedulerProps]);

  const blockProps = {
    schedules,
    channelId,
    numberOfWeeks,
    setNumberOfWeeks,
    blockData: blockData.current,
    setIsDialogOpen,
    titleGroupList,
    seriesList,
    updateItem,
  };

  return (
    <>
      {isDialogOpen && <ModifyBlock {...blockProps} />}
      {openForm === 'IMPORT' && (
        <ImportComponent
          closeModal={() => setOpenForm('')}
          setIsDirty={setIsDirty}
          scheduleInfo={{ ...channelInfo, period }}
          schedulerData={{
            scheduleInfo: {
              ...channelInfo,
              period,
            },
          }}
          sampleTemplate="programmeImportTemplate"
          handleOnImportApi={ImportChannelArchitectureAPI}
          importResultColumns={[
            {
              field: 'week',
              headerText: 'Week',
            },
            {
              field: 'dayOfWeek',
              headerText: 'Day of Week',
            },
            {
              field: 'startTime',
              headerText: 'Start Time',
            },
            {
              field: 'endTime',
              headerText: 'End Time',
            },
            {
              field: 'type',
              headerText: 'Type',
            },
            {
              field: 'genre',
              headerText: 'Genre',
            },
          ]}
          importResultFileTitle="programme_import_result"
        />
      )}
      {isLoading ? (
        <div style={{ height: 'calc(100vh - 100px)' }}>
          <BootstrapSpinner />
        </div>
      ) : (
        <RenderScheduler />
      )}
    </>
  );
}

export default CAProgrammmes;
